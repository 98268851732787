// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-one-on-one-js": () => import("./../../../src/pages/one-on-one.js" /* webpackChunkName: "component---src-pages-one-on-one-js" */),
  "component---src-pages-platforma-mint-web-tuts-js": () => import("./../../../src/pages/platforma-mint-web-tuts.js" /* webpackChunkName: "component---src-pages-platforma-mint-web-tuts-js" */),
  "component---src-pages-proiecte-web-js": () => import("./../../../src/pages/proiecte-web.js" /* webpackChunkName: "component---src-pages-proiecte-web-js" */),
  "component---src-pages-resurse-gratuite-js": () => import("./../../../src/pages/resurse-gratuite.js" /* webpackChunkName: "component---src-pages-resurse-gratuite-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

